<template>
  <v-dialog v-model="open" width="500">
    <v-card v-if="originalTransaction">
      <v-card-title class="headline">{{$t('actions.refund')}}</v-card-title>
      <v-card-text>
        <!-- Añadir cantidad total de la transacción original -->
        <v-layout justify-center class="mt-2 mb-4">
          <div class="title grey--text flex-none">{{$t('fields.original_amount')}}</div>
          <v-spacer/>
          <v-flex class="title text-right" v-if="originalTransaction">
            {{`${formatAmount(Math.abs(originalTransaction.amount))} ${getCurrencyName(originalTransaction.currencyId)}`}}
          </v-flex>
        </v-layout>
        <!-- Añadir cantidad ya reembolsada -->
        <v-layout justify-center class="mt-2 mb-4" v-if="originalTransaction && availableAmount !== originalTransaction.amount">
          <div class="title grey--text flex-none">{{$t('fields.refunded_amount')}}</div>
          <v-spacer/>
          <v-flex class="title text-right">
            {{`${formatAmount(Math.abs(originalTransaction.amount - availableAmount))} ${getCurrencyName(originalTransaction.currencyId)}`}}
          </v-flex>
        </v-layout>
        <!-- Select transaction amount -->
        <price-input v-if="originalTransaction && open"
          :label="$t('fields.amount')"
          :currency="localCurrency"
          :total="availableAmount"
          :value="amount"
          @input="(v) => amount = v"
          displayTotal
          class="mb-2"
          autofocus
          :errorMessages="amount > availableAmount
            ? [$t('transactions.error.over_refund', { curr: formatAmount(amount), max: formatAmount(availableAmount), currencyName: localCurrency.name })]
            : formErrors
              ? formErrors.NOT_ENOUGH_BALANCE
              : null"
        />
        <!-- input concept (optional) -->
        <v-text-field
          v-model="concept"
          :label="$t('fields.concept')"
        />
        <span>{{ $t('transactions.to_be_refunded_in') }}</span><span style="color: var(--v-primary-base)">{{ availablePaymentMethods.find(pM => pM.id === this.originalTransaction.paymentMethodId).name }}</span>
        <v-layout>
          <v-spacer/>
          <v-btn color="primary" :disabled="!isValid" :loading="state.loading" @click="addTransaction()">{{$t('actions.complete_transaction')}}</v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import moneyFormatMixins from '@/mixins/MoneyFormat'

import { mapState, mapGetters } from 'vuex'
import { transactionTypes } from '@/config'
import { getTransactionRefundableAmount } from '@/utils/TransactionUtils'

export default {
  // EMITTED ACTIONS: completedAction
  props: {
    triggerOpen: Date,
    originalTransaction: Object
  },
  mixins: [moneyFormatMixins],
  data () {
    return {
      open: false,
      amount: null,
      formErrors: null,
      rules: {
        required: value => !!value || '',
        notGreaterThanOriginal: value => {
          return (!!value && this.parsePrice(value) <= Math.abs(this.originalTransaction.amount)) || ''
        }
      },
      transactionTypes
    }
  },
  computed: {
    ...mapState(['state', 'transactions', 'paymentMethods']),
    ...mapGetters({
      getCurrencyName: 'configuration/getCurrencyName',
      localCurrency: 'configuration/localCurrency',
      availablePaymentMethods: 'configuration/paymentMethods'
    }),
    concept: {
      get () {
        return this.transactions.edited
          ? this.transactions.edited.concept
          : null
      },
      set (value) {
        this.$store.commit('transactions/setField', {
          field: 'concept',
          value
        })
      }
    },
    isValid () {
      if (this.amount > this.availableAmount) {
        return false
      }
      if (!this.transactions.edited) {
        return false
      }
      if (!this.transactions.edited.amount) {
        return false
      }
      return true
    },
    availableAmount () {
      return this.originalTransaction ? getTransactionRefundableAmount(this.originalTransaction) : 0
    }
  },
  methods: {
    setPaymentMethod () {
      if (this.originalTransaction.type === transactionTypes.PAYMENT) {
        this.$store.dispatch('paymentMethods/list', { save: true }).catch(() => {})
      }
    },
    addTransaction () {
      const { currencyId, type, amount, id, guestId, paymentMethodId } = this.originalTransaction
      const transaction = {
        ...this.transactions.edited,
        guestId,
        currencyId,
        type,
        negativeTransaction: true,
        relatedTransactionIds: [id],
        paymentMethodId
      }
      this.$store.dispatch('transactions/post', [transaction]).then(() => {
          this.$emit('completedAction')
          this.open = false
        })
        .catch((error) => {
          error.body.forEach(e => {
            this.formErrors = {}
            this.formErrors[e.error_code] = this.$te(`error.${e.error_code}`) ? [this.$t(`error.${e.error_code}`)] : []
          })
        })
    }
  },
  watch: {
    triggerOpen () {
      this.open = true
    },
    open (val) {
      if (val) {
        this.setPaymentMethod()
        this.amount = Math.abs(this.availableAmount)
      }
      if (!val) {
        this.formErrors = {}
        this.amount = null
        this.$store.commit('transactions/resetEdited')
      }
    },
    amount (value) {
      this.$store.commit('transactions/setField', {
        field: 'amount',
        value
      })
    }
  },
  components: {
    PriceInput: () => import('@/components/view-components/PriceInput.vue')
  }
}
</script>
<style lang="scss">
.align-vertical {
  height: fit-content;
  margin: auto !important;
}
.v-text-field.currency .v-text-field__slot > input {
  text-align: right;
  margin-right: 5px;
}
</style>
